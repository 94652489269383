@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}


@keyframes rotate--inverse {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-359deg);
	}
}
