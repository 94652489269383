.footer {
	@include container;
	display: flex;
	justify-content: space-between;
	border-top: 3px solid $grey-5;
	padding-top: 8rem;
	padding-bottom: 8rem;
	flex-wrap: wrap;
	font-size: 1.6rem;

	&__left {
		width: 57rem;
		display: flex;

        @include lg {
            width: 50rem;
        }

		@include md {
			width: 100%;
			padding-bottom: 4rem;
            padding-left: 4rem;
            padding-right: 4rem;
		}

        @include sm {
            padding: 0;
        }
	}

	&__logo {
		flex: 0 0 96px;

		img {
			display: block;
		}
	}

	&__text p {
		margin: 0;
		@include sm {
			font-size: 1.4rem;
		}
	}

	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-link {
			color: $body;
			font-weight: bold;
			display: block;
			padding: 0 1.8rem;
			position: relative;
			transition: ease all 0.2s;
			@include sm {
				padding: 0 3rem 0 0;
			}

			&:hover {
				color: $teal;
				text-decoration: none;
			}
		}
	}
	//logos in footer
	//
	&__right {
        flex: 1;
        padding-left: 40px;

        @include lg {
            padding-left: 20px;
        }
    }

	&__items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
    }

    &__item {
        @include sm {
            flex: 0 0 50%;
            text-align: center;
            padding: 20px 0;
        }
    }
}
