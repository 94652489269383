.c-square{
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: 50px;
  width: 100%;
  fill: none;
  stroke: #2bc5c9;
  stroke-miterlimit: 10;
  stroke-width: 1;
  width: 60px;
  height: 60px;
  animation: linear 20s rotate infinite;
  right: 120px;
  z-index: 1;

  &--inverse {
    animation: linear 30s rotate--inverse infinite;
  }

  &--2 {
    width: 30px;
    height: 30px;
    right: 0px;
    bottom: 200px;
  }

  &--3 {
    width: 500px;
    height: 500px;
    right: 80px;
    bottom: -136px;
    z-index: 1;
    stroke-width: 0.01em;
    animation: none;
    transform: rotate(10deg);
    stroke: darken(#2bc5c9, 5);
  }

  &--4 {
    width: 30px;
    height: 30px;
    right: inherit;
    left: -50px;
    bottom: 40px;
  }

  &--5 {
    right: inherit;
    left: 20px;
    bottom: -100px;
  }

  &--6 {
    width: 200px;
    height: 200px;
    right: inherit;
    right: 30px;
    bottom: -10px;
  }
}
