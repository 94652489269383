.dropdown-wrapper {
  padding-top: 47px;
  position: absolute;
  width: 800px;
  left: 4000px;
  transform: rotateX(-15deg);
  transform-origin: 50% 0px;
  transition: left 0s, opacity 0.1s, transform 0.1s;
  opacity: 0;

  .scrolled & {
    padding-top: 20px;
  }


  &::before {
    content: "";
    width: 23px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: 135px;
  }
}

.dropdown {
  background-color: #fff;
  border-radius: 0 0 $border-radius $border-radius;
  padding: 9px;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.12);

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px;
    position: relative;
    border-radius: $border-radius;
    border-bottom: 3px solid #fff;
    flex: 0 0 49%;

    @media (max-width: 599px){
      flex: 0 0 100%;
    }

    &::after {
      // content: "";
      // width: 100%;
      // position: absolute;
      // bottom: 0;
      // width: 100%;
      // background-color: #ebebeb;
      // height: 1px;
      // left: 0;
    }

    &:last-child {
      &::after {
        display: none;

        @include sm {
          display: block;
        }
      }
    }

    &:hover {
      text-decoration: none;
      border-bottom: 3px solid $yellow;

      .dropdown__item-icon {
        filter: none;
      }

      .dropdown__item-name {
        color: $teal;
        transition: all ease 0.3s;
      }

      .dropdown__item-description {
        color: $grey-2;
      }

      &::after {
        display: none;
      }
    }

    &-icon {
      text-align: right;
      padding-right: 10px;
      filter: grayscale(100%);
      transition: all ease 0.3s;
      width: 85px;
      margin-top: -10px;

      & img {
        max-width: 100%;
      }
    }

    &-content {
      flex: 1;
    }

    &-name {
      font-size: 14px;
      margin: 0;
      color: $grey-3;
      font-weight: bold;
      margin-bottom: 4px;
    }

    &-description {
      font-size: 11px;
      margin: 0;
      color: $grey-3;
    }
  }
}
