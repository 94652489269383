// Phones
$mobile: 767px;

// Tablets
$tablet: 991px;

// Desktop
$desktop: 1199px;

// Small devices
@mixin sm {
   @media (max-width: #{$mobile}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (max-width: #{$tablet}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (max-width: #{$desktop}) {
       @content;
   }
}

// containers

@mixin container {
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;

  @media (max-width: #{$desktop}) {
    max-width: 990px;
  }

  @media (max-width: #{$tablet}) {
    max-width: 750px;
  }
}


// Animation Mixin

@mixin animate($animation, $duration, $repeat, $easing) {
	-webkit-animation: $animation $duration $repeat $easing;
	   -moz-animation: $animation $duration $repeat $easing;
	    -ms-animation: $animation $duration $repeat $easing;
	        animation: $animation $duration $repeat $easing;
}


// Keyframes Mixin

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}
