.form {
  padding-top: 20px;
  display: none;

  .submitted-message {
    color: $yellow;
    font-size: 15px;
  }

  .input {
    line-height: 1;
  }

  .hs-form-field {
    position: relative;
    margin-bottom: 20px;
    text-align: right;

      label {
        position: absolute;
        font-size: 15px;
        z-index: 2;
        background: #fff;
        padding: 0 4px;
        top: -10px;
        left: 10px;
      }

      .hs-error-msgs {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;

        label {
          position: absolute;
          top: inherit;
          left: inherit;
          top: -8px;
          color: red;
          font-size: 11px;
          right: 5px;
        }
      }

  }

  input,
  textarea {
    border-radius: $border-radius;
    border: none;
    width: 100%;
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid $grey-4;
    position: relative;
    appearance: none !important;

    &:focus {
      outline: 0;
      border: 1px solid $yellow;
    }

    &.invalid {
      border: 1px solid red;
    }
  }

  input {
    height: 5.6rem;
  }

  textarea {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 5.6rem;
  }

  input[type="submit"] {
    background: $red;
    color: #fff;
    border-color: $red;
    cursor: pointer;
    transition: ease all 0.5s;
    appearance: none;

    &:hover {
      background: darken($red, 10);
      border-color: darken($red, 10);
      transform: translateY(-5px);
    }
  }

}
