@import "../variables";
@import "../mixins";

.landing-intro {
	padding-top: 40px;
  @include container;
  text-align: center;

	p {
		font-size: 1.2em;
	}

  &__headline::after {
    left: 50%;
    transform: translateX(-50%);
  }

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 20px;
		padding-bottom: 100px;

		.c-btn {
			margin: 0 20px;
		}
	}
}
