@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-BoldItalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
