.header-section {
  width: 100%;
  display: block;
  height: 540px;
  position: relative;
  background: none;
  background: linear-gradient(0deg, rgba(0,145,166,1) 41%, rgba(37,198,184,1) 100%);
  overflow: hidden;

  @include sm {
    height: auto;
    margin-bottom: 40px;
  }

  &--contact {
    height: 540px;

    @include sm {
      height: 480px;
    }
  }

  &__curve{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 250px;
    fill: white;
    z-index: 2;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('../img/header-overlay.png');
    background-repeat: no-repeat;
    background-position: left top;
    top: 0;
    background-size: cover;
  }
}

.header {
  @include container;
  padding-top: 21rem;
  position: relative;

  @include sm {
    padding-top: 10rem;
    padding-bottom: 0;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('../img/header-overlay-1.png');
    background-repeat: no-repeat;
    background-position: left top;
    top: 0;
    left: -500px;
  }

  &__text {
    color: #fff;
    position: relative;
    z-index: 3;

    @include sm {
      padding-top: 40px;
      padding-bottom: 80px;
    }
  }

  &__headline {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    position: relative;
    margin-bottom: 2.5rem;

    &--no-icon {
      margin-top: 48px;
    }
  }

  &__logo {
    margin-top: 76px;
    margin-bottom: 10px;
  }

  &__paragraph {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 400;

    @include sm {
      font-size:2rem;
    }

    br {
      @include sm {
        display: none;
      }
    }
  }

  &__icon {
    width: 85px;
    margin-left: -20px;
  }
}
