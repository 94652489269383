.card {
  padding: 3rem;
  background: #fff;
  border-radius: $border-radius;
  border: 1px #333;
  border-bottom: 3px solid #fff;
  transition: transform ease 1s;

  @include sm {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &--active {
    border-bottom: 3px solid $yellow;
  }

  &:hover {
    transform: translateY(-5px);
    border-bottom: 3px solid $yellow;
  }

  &__content {
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }

  &__button {
    padding-right: 2.5em;
    padding-left: 2.5em;
  }

  &__title {
    position: relative;
    padding-bottom: 1.2rem;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $yellow;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__description {
    font-size: 15px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
