.request-demo-section {
  padding: 7rem 0;
  position: relative;
  z-index: 10;

  &--two {
    margin-top: -32rem;
    z-index: 5;
    position: relative;

    @include sm {
      margin-top: -29rem;
    }
  }
}

.request-demo {
  @include container;

  &__paragraph {
    text-align: center;
    font-size: 2.7rem;
    padding: 0 10rem;
    margin: 0 auto;
    width: 90%;

    .request-demo-section--two & {
      margin-bottom: 7rem;
    }

    .request-demo-section--two & {
      color: #fff;
    }

    @include lg {
      padding: 0;
    }

    @include md {
      font-size: 2.2rem;
    }

    @include sm {
      font-size: 1.8rem;
      width: 100%;
    }
  }

  &__form-wrapper {
    background-color: #fff;
    border-radius: $border-radius;
    width: 75%;
    margin: 0 auto;
    padding: 4rem;

    @include md {
      width: 100%;
      padding-bottom: 0;
    }

    @include sm {
      padding: 4rem 2rem;
      padding-bottom: 0;
    }

    h3 {
      text-align: center;
      font-size: 3rem;
      margin: 0;
      padding: 0;
      margin-bottom: 4rem;
      letter-spacing: normal;

      @include sm {
        font-size: 2.6rem;
      }
    }
  }

  &__form {
    width: 70%;
    margin: 0 auto;

    @include md {
      width: 80%;
    }

    @include sm {
      width: 100%;
    }

    .hs-form {
      display: flex;
      width: 100%;

      @include sm {
        flex-wrap: wrap;
      }

      .hs-email{
        flex: 2;

        @include sm {
          flex: 0 0 100%;
        }

        & > label {
          display: none;
        }
      }

      .hs-error-msgs {
        list-style: none;
        padding: 0;
        margin: 0;
        color: red;
        padding-left: 20px;
        margin-top: 10px;
      }

      .hs-submit {
        flex: 1;
      }

      input[type="email"] {
        height: 5.6rem;
        border-radius: $border-radius 0 0 $border-radius;
        border: none;
        width: 100%;
        font-size: 1.8rem;
        padding-left: 2rem;
        border-top: 1px solid $red;
        border-left: 1px solid $red;
        border-bottom: 1px solid $red;
        appearance: none;

        @include sm {
          border-right: 1px solid $red;
          border-radius: $border-radius;
          margin-bottom: 5px;
          text-align: center;
          padding: 0 1rem;
        }
      }

      input[type="submit"] {
        border-radius: 0 $border-radius $border-radius 0;
        background: $red;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 1.8rem;
        letter-spacing: -0.04em;
        height: 5.6rem;
        border-top: 1px solid $red;
        border-right: 1px solid $red;
        border-bottom: 1px solid $red;
        transition: ease background 0.5s;
        padding: 0 3rem;
        appearance: none;

        @include sm {
          border-left: 1px solid $red;
          border-radius: $border-radius;
          width: 100%;
        }

        &:hover {
          background: $red;
        }
      }
    }
  }
}


.hs-field-desc {
    font-size: 10px;
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 10px;
    color: $grey-4;
}

.hs_error_rollup {
    display: none !important;
}
