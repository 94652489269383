.text-center {
  text-align: center;

  &::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

html, body {
  overflow-x: hidden;
}

body.iphone-fix {
  cursor: pointer;
}

br.d-mobile {
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
}
